import React from 'react'
import useWindowDimensions from "../lib/useWindowDimensions"
import styles from '../styles/Page.module.css'

export default React.forwardRef((props, ref) => {
  const { windowHeight } = useWindowDimensions()

  const paddingTop = () => {
    // 115px is the header height + 50px margin
    return props.first ? 145 : 50
  }

  const minHeight = () => {
    return props.last ? windowHeight - paddingTop() - 290 : windowHeight - paddingTop()
  }

  return (
    <div style={{minHeight: minHeight(), backgroundColor: props.backgroundColor || 'transparent', paddingTop: paddingTop()}}
         className={styles.page}>
      <div id={props.id} ref={ref} className={styles.anchor} styles={props.first ? {top: 0} : {}}></div>
      {props.children}
    </div>
  )
})