class Element{
  constructor({ctx, x, y, width, height, speed, amplitude, zIndex}) {
    this.ctx = ctx
    this.x = x
    this.y = y
    this.width = width
    this.height = height
    this.speed = speed
    this.amplitude = amplitude
    this.phase = Math.random() * Math.PI * 2
    this.zIndex = zIndex || 0
  }

  onClick(x, y){
    return false
  }

  xOffset(){
    throw new Error("Override xOffset!")
  }

  yOffset(){
    throw new Error("Override yOffset!")
  }

  draw(){
    throw new Error("Override draw!")
  }

  move(delta){
    this.phase += delta * this.speed
    this.phase %= Math.PI * 2
  }
}

export default Element