import LogoImage from '../images/logo.svg'
import Element from './Element'

class Logo extends Element{
  constructor(props) {
    super(props)
    this.image = new Image()
    this.image.src = LogoImage
    this.turbo = 1
  }

  onClick(x, y) {
    if (x > this.x + this.xOffset() &&
      x < this.x + this.xOffset() + this.width &&
      y > this.y + this.yOffset() &&
      y < this.y + this.yOffset() + this.height
    ){
      this.turbo = 40
    }
  }

  move(delta){
    this.turbo = Math.max(1, this.turbo - delta * 0.03)
    this.phase += delta * this.speed * this.turbo
    this.phase %= Math.PI * 2
  }

  xOffset(){
    return 0
  }

  yOffset(){
    return Math.sin(this.phase) * this.amplitude
  }

  draw() {
    this.ctx.drawImage(this.image, this.x + this.xOffset(), this.y + this.yOffset(), this.width, this.height * 0.9)
  }
}

export default Logo