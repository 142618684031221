import Element from './Element'

class DataLine extends Element{
    constructor({ctx, startPos, endPos, interval, delay, color}) {
        super({ctx})
        this.startPos = startPos
        this.endPos = endPos
        this.width = 2
        this.color = color
        this.packets = []
        this.lineLength = this.resolveLineLength()
        this.lineAngle = this.resolveLineAngle()
        //console.log(this.lineAngle)

        if (interval) {
            window.setTimeout(() => {
                window.setInterval(() => {
                    this.packets.push(0)
                }, interval)
            }, delay)
        }
    }

    move(delta){
        this.packets = this.packets.map((p, i) => {
            const pos = p + delta * 0.002
            if (pos > 1) {
                return null
            }
            return pos
        }).filter(p => p !== null)
    }

    draw() {
        // Reset the current path
        this.ctx.beginPath()
        this.ctx.lineWidth = this.width
        this.ctx.strokeStyle = `rgba(255, 255, 255, 0.25)`
        // Staring point (10,45)
        this.ctx.moveTo(...this.startPos)
        // End point (180,47)
        this.ctx.lineTo(...this.endPos)
        // Make the line visible
        this.ctx.stroke()

        this.packets.forEach(p => {
            const x = this.startPos[0] + Math.cos(this.lineAngle) * this.lineLength * p
            const y = this.startPos[1] + Math.sin(this.lineAngle) * this.lineLength * p
            this.ctx.beginPath()
            this.ctx.arc(x, y, 5, 0, 2 * Math.PI, false)
            this.ctx.fillStyle = `rgba(${this.color[0]}, ${this.color[1]}, ${this.color[2]}, 0.9)`
            this.ctx.fill()
            this.ctx.lineWidth = 3
            this.ctx.strokeStyle = `rgba(${this.color[0]}, ${this.color[1]}, ${this.color[2]}, 0.25)`
            this.ctx.stroke()
        })
    }

    resolveLineAngle() {
        return Math.atan2(this.endPos[1] - this.startPos[1], this.endPos[0] - this.startPos[0])
    }

    resolveLineLength() {
        return Math.sqrt((this.startPos[0] - this.endPos[0]) ** 2 + (this.startPos[1] - this.endPos[1]) ** 2)
    }
}

export default DataLine
