import React, {useEffect, useRef, useState} from 'react'
import styles from '../styles/Contact.module.css'
import useWindowDimensions from "../lib/useWindowDimensions"
import AnimatedCanvas from "./AnimatedCanvas"
import Cube from "../classes/Cube"
import Line from "../classes/Line"

export default () => {
  const canvasRef = useRef(null)
  const { windowWidth } = useWindowDimensions()
  const [ elements, setElements ] = useState([])

  useEffect(() => {
    initElements()
  }, [])

  useEffect(() => {
    initElements()
  }, [windowWidth])

  const canvasWidth = () => (
    Math.min(windowWidth * 0.3, 300)
  )

  const canvasHeight = () => (
    canvasWidth() * 1.9
  )

  const initElements = () => {
    const ctx = canvasRef.current.getContext('2d')
    let elements = []
    /* FROM BOTTOM TO TOP */
    elements.push(new Cube({
      ctx: ctx,
      y: canvasHeight() * 0.55,
      x: canvasWidth() * 0.6,
      width: canvasWidth() * 0.08,
      height: canvasHeight() * 0.08,
      speed: 0.00051,
      amplitude: canvasHeight() * 0.03,
      zIndex: 2
    }))
    elements.push(new Cube({
      ctx: ctx,
      y: canvasHeight() * 0.45,
      x: canvasWidth() * 0.25,
      width: canvasWidth() * 0.08,
      height: canvasHeight() * 0.08,
      speed: 0.00053,
      amplitude: canvasHeight() * 0.032,
      zIndex: 2
    }))
    elements.push(new Line({
      ctx: ctx,
      startElement: elements[elements.length - 2],
      endElement: elements[elements.length - 1],
      color: "rgba(39,37,109, 0.25)",
      width: canvasWidth() * 0.01,
      zIndex: 1
    }))
    elements.push(new Cube({
      ctx: ctx,
      y: canvasHeight() * 0.3,
      x: canvasWidth() * 0.75,
      width: canvasWidth() * 0.08,
      height: canvasHeight() * 0.08,
      speed: 0.00056,
      amplitude: canvasHeight() * 0.033,
      zIndex: 2
    }))
    elements.push(new Line({
      ctx: ctx,
      startElement: elements[elements.length - 3],
      endElement: elements[elements.length - 1],
      color: "rgba(39,37,109, 0.25)",
      width: canvasWidth() * 0.01,
      zIndex: 1
    }))
    elements.push(new Line({
      ctx: ctx,
      startElement: elements[elements.length - 5],
      endElement: elements[elements.length - 2],
      color: "rgba(39,37,109, 0.25)",
      width: canvasWidth() * 0.01,
      zIndex: 1
    }))
    elements.push(new Cube({
      ctx: ctx,
      y: canvasHeight() * 0.25,
      x: canvasWidth() * 0.275,
      width: canvasWidth() * 0.12,
      height: canvasHeight() * 0.12,
      speed: 0.0005,
      amplitude: canvasHeight() * 0.03,
      zIndex: 2
    }))
    elements.push(new Line({
      ctx: ctx,
      startElement: elements[elements.length - 4],
      endElement: elements[elements.length - 1],
      color: "rgba(39,37,109, 0.25)",
      width: canvasWidth() * 0.01,
      zIndex: 1
    }))
    elements.push(new Cube({
      ctx: ctx,
      y: canvasHeight() * 0.12,
      x: canvasWidth() * 0.6,
      width: canvasWidth() * 0.08,
      height: canvasHeight() * 0.08,
      speed: 0.0004,
      amplitude: canvasHeight() * 0.025,
      zIndex: 2
    }))
    elements.push(new Line({
      ctx: ctx,
      startElement: elements[elements.length - 3],
      endElement: elements[elements.length - 1],
      color: "rgba(39,37,109, 0.25)",
      width: canvasWidth() * 0.01,
      zIndex: 1
    }))
    setElements(elements)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1>
          Ota yhteyttä!
        </h1>
        <p>
          Jukka Hirvonen (tj)<br/>
          +358 50 341 5770<br/>
          jukka@sensormonitor.fi
        </p>
        <p>
          SensorMonitor<br/>
          Y-tunnus: 2840799-6
        </p>
      </div>
      <div className={styles.animation}>
        <AnimatedCanvas ref={canvasRef}
                        width={canvasWidth()}
                        height={canvasHeight()}
                        elements={elements}
                        style={{position: "absolute", top: 0, right: 0}}
        />
      </div>
    </div>
  )
}