import Element from './Element'

class Bubble extends Element{
  constructor(props) {
    super(props)
    this.length = props.length
    this.phase = Math.random() * Math.PI * 2
  }

  yOffset(){
    return (this.phase / (Math.PI * 2)) * this.height
  }

  draw() {
    // linear gradient from start to end of line
    const grad = this.ctx.createLinearGradient(this.x, this.height - this.yOffset(), this.x, this.height - this.yOffset() + this.length)
    const opacity = (1 - Math.cos(this.phase)) * 0.1
    //grad.addColorStop(0, `rgba(152,97,91,${opacity})`)
    //grad.addColorStop(1, "rgba(152,97,91,0)")
    grad.addColorStop(0, `rgba(169,122,106,${opacity})`)
    grad.addColorStop(1, "rgba(169,122,106,0)")

    this.ctx.strokeStyle = grad
    this.ctx.lineWidth = this.width

    this.ctx.beginPath()
    this.ctx.moveTo(this.x, this.height - this.yOffset())
    this.ctx.lineTo(this.x, this.height - this.yOffset() + this.length)

    this.ctx.stroke()
  }
}

export default Bubble
