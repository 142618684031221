import React from 'react'
import classes from '../styles/CookieConsentDialog.module.css'

export default ({setShowCookiePolicy, onAllow, onDeny}) => {
  return (
    <div className={classes.container}>
      <span>Käytämme evästeitä Googlen Analytics palvelun kävijäseurantaa varten (<a href='#cookie-policy'
                                                                                     onClick={() => setShowCookiePolicy(true)}>lue lisää</a>).</span>
      <button onClick={onDeny}>Kiellä</button>
      <button onClick={onAllow}>Salli</button>
    </div>
  )
}
