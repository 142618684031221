import Element from './Element'

class Line extends Element{
  constructor({ctx, startElement, endElement, color, width}) {
    super({ctx})
    this.startElement = startElement
    this.endElement = endElement
    this.width = width
    this.color = color || "rgba(230, 230, 255, 0.5)"
  }

  startPos(){
    return [
      this.startElement.x + this.startElement.xOffset() + this.startElement.width * 0.5,
      this.startElement.y + this.startElement.yOffset() + this.startElement.height * 0.5
    ]
  }

  endPos(){
    return [
      this.endElement.x + this.endElement.xOffset() + this.endElement.width * 0.5,
      this.endElement.y + this.endElement.yOffset() + this.endElement.height * 0.5
    ]
  }

  draw() {
    // Reset the current path
    this.ctx.beginPath()
    this.ctx.lineWidth = this.width
    this.ctx.strokeStyle = this.color
    // Staring point (10,45)
    this.ctx.moveTo(...this.startPos())
    // End point (180,47)
    this.ctx.lineTo(...this.endPos())
    // Make the line visible
    this.ctx.stroke()
  }
}

export default Line