import React, {useEffect, useRef} from "react"

export default React.forwardRef((props, ref) => {
  const frameRef = useRef(null)
  const lastAnimationTimeRef = useRef(null)

  useEffect(() => {
    if (props.elements && props.elements.length > 0)
      startAnimation()
    return () => stopAnimation()
  }, [props.width, props.elements])

  const startAnimation = () => {
    if( frameRef.current === null )
      frameRef.current = window.requestAnimationFrame( animationLoop )
  }

  const animationLoop = () => {
    const now = Date.now()
    let delta = 0

    if (lastAnimationTimeRef.current !== null)
      delta = (now - lastAnimationTimeRef.current)

    lastAnimationTimeRef.current = now

    frameRef.current = window.requestAnimationFrame( animationLoop )

    const ctx = ref.current && ref.current.getContext('2d')
    if (ctx){
      ctx.clearRect(0, 0, (props.width || 0) + 10, (props.height || 0))
    }

    (props.elements).sort((a, b) => a.zIndex > b.zIndex ? 1 : -1).forEach(e => {
      e.move(delta)
      e.draw()
    })
  }

  const stopAnimation = () => {
    if (frameRef.current !== null){
      //console.log("Stopping animation loop")
      window.cancelAnimationFrame( frameRef.current )
      frameRef.current = null
      lastAnimationTimeRef.current = null
    }
  }

  const handleCanvasClick = (e) => {
    const x = e.pageX
    const y = e.pageY

    const elemRect = ref.current.getBoundingClientRect()
    const yOffset = elemRect.top + window.scrollY
    const xOffset = elemRect.left + window.scrollX

    //console.log(x - xOffset)
    //console.log(y - yOffset)
    props.elements.forEach(element => element.onClick(x - xOffset, y - yOffset))
  }

  return (
    <canvas
      style={props.style}
      ref={ref}
      width={props.width}
      height={props.height}
      onClick={handleCanvasClick}
    />
  )
})