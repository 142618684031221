import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './fonts/RedHatText-Regular.ttf'
import './fonts/RedHatText-Medium.ttf'
import './fonts/RedHatText-Bold.ttf'
import './styles/fonts.css'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
