import React from 'react'
import styles from '../styles/AboutCookies.module.css'

export default ({visible, onAllow, onDeny}) => {
  return (
    <div className={visible ? styles.popup : styles.popupClosed}>
      <div className={styles.content}>
        <h1>Tietoa evästeistä</h1>
        <h2>Tietoa SensorMonitor-sivuston käyttämistä evästeistä</h2>
        <p>
        Eväste on pieni tekstitiedosto, joka tallennetaan käyttäjän tietokoneelle, kun hän vierailee sivustossa. Evästeet eivät sisällä henkilötietoja eikä niiden avulla voida suorittaa ohjelmia tai tallentaa viruksia käyttäjän tietokoneelle.
        </p>
        <h2>Evästeet tällä sivustolla</h2>
        <p>
        Käytämme evästeitä Googlen Analytics palvelun kävijäseurantaa varten. Seurannan tarkoitus on kerätä tilastotietoa esimerkiksi sivun kävijämäärästä, sekä suosituimmista sisällöistä.
        </p>
        <p>
          Lisätietoja Googlen evästeistä voit lukea täältä: <a rel="noopener noreferrer" href="https://www.google.com/policies/technologies/cookies/" target="_blank">Googlen evästekäytäntö</a>. Evästetiedot auttavat meitä parantamaan sivustomme toimivuutta ja käytettävyyttä.
        </p>
        <div className={styles.actions}>
          <button onClick={onDeny}>Kiellä evästeet</button>
          <button onClick={onAllow}>Salli evästeet</button>
        </div>
      </div>
    </div>
  )
}
